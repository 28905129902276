<template>
    <div id="content" class="home" :class="[$mq]">
        <div class="content full hide-scrollbar">
            <div class="col notes hide-scrollbar" ref="container">
                <Message v-if="trailDaysLeft" :message="trialMessage ? trialMessage : $tc('trial.message', trailDaysLeft == 1 ? 1 : 2, { days: trailDaysLeft, account: accountName })" :hasActions="false" :portraitDeployed="true"> </Message>
                <template v-if="Object.keys(notes).length > 0">
                    <div class="list-notes scrollbar scrollbar-transparent" :class="[{ 'with-trial': trailDaysLeft }, { 'editing-img': isEditingWithImage }, { editing: isEditing }]">
                        <div class="date" v-for="(group, date) in notes" :key="date">
                            <div class="separator">
                                <div class="caption">{{ date }}</div>
                            </div>
                            <note v-for="note in group" :note="note" :key="note.id"></note>
                        </div>
                    </div>
                </template>
                <template v-else-if="notesLoaded">
                    <!-- <div class="empty">{{ $t('notes.empty_notes') }}</div> -->
                    <Message :message="$t('notes.empty_notes')" type="info"></Message>
                </template>

                <reply @editingWithImage="editingWithImage" @editingWithoutImage="editingWithoutImage"></reply>
            </div>

            <template v-if="!['portrait'].includes($mq)">
                <div class="col alerts hide-scrollbar">
                    <!-- <vue-speech lang="es-ES" @onTranscriptionEnd="onEnd" style="display: none;" />
                <Message :message="speech" type="warning"></Message> -->

                    <div class="section">
                        <div class="calendar-container">
                            <calendar @select="selectDate" :doToggle="false" :goToFuture="false" condensed :selected="date.format('YYYY-MM-DD')"></calendar>
                        </div>

                        <div class="list" v-for="tool in allAlerts">
                            <span class="name">{{ tool.name }}</span>

                            <div v-for="alert in tool.alerts" @click="navTool(alert.route)" v-if="Object.keys(tool.alerts).length > 0">
                                <div class="item" :class="alert.state">
                                    <div class="value">{{ alert.value }}</div>
                                    <div class="content">
                                        <div class="name">{{ alert.name }}</div>
                                    </div>
                                    <div class="icon"></div>
                                </div>
                            </div>
                            <div v-if="Object.keys(tool.alerts).length <= 0" class="all-done">
                                <span class="ico"></span>
                                <span class="name">{{ $t('home.all_done') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="section activity">
                        <h6 class="titl">{{ $t('home.activity.title') }}</h6>
                        <div class="line" v-for="line in allActivity" v-if="line">
                            <span class="value">{{ line.value }}</span>
                            <span class="name">{{ line.name }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Message from '@/components/domain/ui/Message'
import calendar from '@/components/layout/Calendar'
import note from '@/components/domain/home/note'
import reply from '@/components/domain/home/reply'

export default {
    name: 'Home',
    components: { Message, calendar, note, reply },
    data() {
        return {
            //   date: moment(),
            speech: '',
            uniqueEdit: '',
            addNewNote: '',
            addNote: false,
            newNote: {
                id: 'new',
                image: '',
                message: '',
                created_date: '',
                employee: false,
                actions: {},
                comments: {}
            },
            allAlertsData: {},
            allActivityData: {},
            notesLoaded: false,
            isEditingWithImage: false,
            isEditing: false
        }
    },
    computed: {
        moment: function () {
            return moment()
        },

        date: {
            get() {
                return moment(this.$store.getters['tasks/currentDate'])
            },
            set(value) {
                this.$store.commit('tasks/setCurrentDate', value.format('YYYY-MM-DD'))
            }
        },

        tools() {
            let tools = this.$store.getters['getTools']
            return tools ? Object.keys(tools) : []
        },

        allAlerts() {
            var self = this
            self.allAlertsData = {}

            if (self.tools) {
                // TASKS
                if (self.tools.indexOf('1') != -1) {
                    let tasks_alerts = this.$store.getters['tasks/getStats']('alerts')
                    Vue.set(self.allAlertsData, '1', {
                        name: self.$t('home.tasks.title'),
                        alerts: tasks_alerts || {}
                    })
                }
                // ISSUES
                let issues_config = this.$store.getters['issues/getConfig']
                let issues_states = this.$store.getters['getIssueStates']
                if (self.tools.indexOf('2') != -1 && issues_config.has_state && issues_states) {
                    let issues_alerts = this.$store.getters['issues/getStats']('alerts')
                    Vue.set(self.allAlertsData, '2', {
                        name: self.$t('home.issues.title'),
                        alerts: issues_alerts || {}
                    })
                }
            }

            return self.allAlertsData
        },

        allActivity() {
            var self = this
            var currentDate = moment(this.date).set({ s: 0, ms: 0 }).format('YYYY-MM-DD')
            self.allActivityData = []

            if (this.tools) {
                // TASKS
                if (this.tools.indexOf('1') != -1) {
                    let tasks_done = self.$store.getters['tasks/getStats']('activity')
                    if (tasks_done && Object.keys(tasks_done).length > 0) {
                        self.allActivityData.push(tasks_done['done'])
                    }
                }

                // ISSUES
                if (this.tools.indexOf('2') != -1) {
                    let issues_created = self.$store.getters['issues/getStats']('activity')
                    if (issues_created && Object.keys(issues_created).length > 0) {
                        self.allActivityData.push(issues_created['created'])
                    }
                }

                // REGISTERS
                if (this.tools.indexOf('4') != -1) {
                    let register_created = self.$store.getters['register/getStats']('activity')
                    if (register_created && Object.keys(register_created).length > 0) {
                        self.allActivityData.push(register_created['created'])
                    }
                }
            }
            return self.allActivityData
        },

        // allNotes() {
        //   // console.log(this.$store.getters['notes/getAllLogs']);
        //   return this.$store.getters["notes/getAllLogs"];
        // },
        notes() {
            var notes = this.$store.getters['notes/getAllLogs']
            var group = {}

            if (notes) {
                var sortedNotes = Object.values(notes).sort((a, b) => (a.created_date > b.created_date ? 1 : b.created_date > a.created_date ? -1 : 0))
                if (sortedNotes) {
                    for (var note in sortedNotes) {
                        var idx = sortedNotes[note]
                        if (idx) {
                            // TODO - Do not translate all dates
                            var date = formatRelative(idx.created_date, 'DD/MM/YYYY', true)
                            if (moment().diff(idx.created_date, 'days') <= 2) {
                                date = i18n.t(date)
                            }
                            if (typeof group[date] == 'undefined') {
                                group[date] = []
                            }
                            group[date].push(idx)
                        }
                    }
                }
            }
            return group
        },

        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        currentEmployee() {
            if (this.emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](this.emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },
        hasAccess() {
            if (this.currentEmployee) {
                return this.$store.getters['hasAccess'](this.currentEmployee.id, 0)
            } else if (this.isNew) {
                return this.isNew
            } else {
                return false
            }
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        accountName() {
            const location = this.$store.getters['loginUser/getLocalLocation']
            const account_name = location && typeof location.account_name !== 'undefined' ? location.account_name : ''
            return account_name
        },
        trailDaysLeft() {
            return this.$store.getters['login/getTrialDaysLeft']
        },
        trialMessage() {
            return this.$store.getters['login/getTrialMessage']
        }
    },
    mounted() {
        const self = this

        this.$nextTick(() => {
            self.scrollToBottom()
        })
    },
    methods: {
        editingWithImage(param) {
            this.isEditingWithImage = param
        },
        editingWithoutImage(param) {
            this.isEditing = param
        },
        selectDate(date) {
            let now = moment()
            this.date = moment(date).set({ h: now.get('h'), m: now.get('m') })
            this.load()
        },
        resetText() {
            this.addNewNote = ''
        },

        load() {
            var home = this.$store.getters.getHome
            const kitchenMode = localStorage.getItem('kitchenMode') ? localStorage.getItem('kitchenMode') === 'true' : false
            const plsMode = localStorage.getItem('plsMode') ? localStorage.getItem('plsMode') === 'true' : false
            if (plsMode) {
                this.$router.push({ name: 'Pls' })
            } else if (kitchenMode) {
                this.$router.push({ name: 'HomeWidgets' })
            } else if (home) {
                this.$router.push(home)
            } else {
                this.$router.push({ name: 'Chat' })
                /* 
                var self = this
                self.$overlay.loading()

                // load registers stats
                self.$store
                    .dispatch('register/loadStats', {
                        date: self.date.format('YYYY-MM-DD')
                    })
                    .then(function (response) {})
                // Load Issues
                var issueStates = self.$store.getters.getIssueStates
                var issuesStatesAlerts = []
                for (var issuesState in issueStates) {
                    var idx = issueStates[issuesState]
                    if (idx.alert) {
                        issuesStatesAlerts.push(idx.id)
                    }
                }
                self.$store.dispatch('checklist/loadIssues', { state: self.date.format('YYYY-MM-DD'), status: issuesStatesAlerts.toString() }).then(function () {
                    // Load issues stats
                    let states = self.$store.getters['getIssueStates']
                    self.$store
                        .dispatch('issues/loadStats', {
                            date: self.date.format('YYYY-MM-DD HH:mm'),
                            states: states
                        })
                        .then(function (response) {})
                })

                // Load Tasks
                self.$store.dispatch('tasks/loadAll', { date: self.date.unix() }).then(function (response) {
                    // load tasks stats
                    self.$store
                        .dispatch('tasks/loadStats', {
                            date: self.date.format('YYYY-MM-DD HH:mm')
                        })
                        .then(function (response) {})
                })

                self.refreshNotes(false)
                self.$overlay.hide() */
            }
        },

        refreshNotes(noScroll) {
            var self = this

            this.$store.dispatch('notes/loadLogs', {}).then(function (response) {
                self.notesLoaded = true
                if (!noScroll) {
                    self.scrollToBottom()
                }
                self.$overlay.hide()
            })
        },

        navTool(route) {
            let self = this

            var href = ''
            var props = {}

            if (typeof route === 'object') {
                href = route.name
                props = route.params
            } else {
                href = route
            }

            if (self.$store.getters['hasAccess'](self.currentEmployee.id, 0)) {
                self.$router.push({ name: self.redirectPath(href), params: props })
                // self.$router.push(self.redirectPath(route));
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](0),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.$router.push({
                                name: self.redirectPath(href),
                                params: props
                            })
                            // self.$router.push(self.redirectPath(route));
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },
        scrollToBottom() {
            setTimeout(() => {
                const container = document.querySelector('.list-notes')
                if (container) {
                    container.scrollTop = container.scrollHeight
                }
            }, 500)
        },
        onEnd({ lastSentence, transcription }) {
            // `lastSentence` is the last sentence before the pause
            // `transcription` is the full array of sentences
            log('onEnd SPEECH')
            log(lastSentence)
            log(transcription)
            this.speech = transcription.join(' ')
            this.speech = lastSentence
            log(this.speech)
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.home {
    > .content {
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(center);
        background-color: transparent;
        height: 100% !important;
        padding-bottom: 0 !important;

        .col {
            height: calc(100%);

            .date {
                padding-top: 10px;
            }

            .section {
                display: block;
                width: 100%;
                height: auto;
                padding: 6px 0;
                overflow: auto;
            }

            &.notes {
                height: calc(100%);
                width: 60%;
                max-width: calc(100% - 300px);
                overflow: auto;
                padding: 10px 10px 0 10px;
                position: relative;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                > .empty {
                    margin-top: 60px;
                    padding-top: 85px;
                }

                .separator {
                    @include display-flex();
                    @include justify-content();
                    position: relative;
                    text-align: center;

                    .caption {
                        @include font-size(xs);
                        @include background($color: $neutro);
                        @include border-radius(4px);
                        padding: 6px;
                        font-family: $conden-bold;
                        color: $main-s10;
                        z-index: 1;
                        text-transform: uppercase;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        border-top: 1px solid $inactive;
                        background: $inactive;
                        width: 100%;
                        transform: translateY(-50%);
                    }
                }

                .list-notes {
                    height: calc(100% - 80px);
                    padding: 0px 12px 12px 12px;
                    width: 100%;
                    position: relative;

                    &.with-trial {
                        max-height: calc(100% - 70px - 12px - 100px);
                    }

                    &.editing-img {
                        height: calc(100% - 205px);
                    }
                    &.editing {
                        height: calc(100% - 130px);
                    }
                }
            }

            &.alerts {
                @include default-box-shadow();
                @include border-radius($default-border-radius);
                background-color: $default-bg-color;
                width: 40%;
                min-width: 300px;
                height: calc(100% - #{$alex-app-padding});
                margin: $alex-app-padding 0 0 0;
                overflow: auto;

                .calendar-container {
                    width: 100%;
                    padding: 6px 0;

                    .calendar {
                        margin: 0 auto;
                        border-bottom: 1px solid #f5f5f5;
                        padding: 0 20px;

                        header .current-date {
                            margin-bottom: 6px;
                        }
                    }
                }

                .list {
                    @include background($size: 100px, $position: right -50px center);
                    max-width: 600px;
                    margin: 0 auto;
                    margin-bottom: 24px;
                    padding: 0 12px;

                    > .name {
                        @include font-size(xs);
                        color: #888;
                        text-transform: uppercase;
                        font-family: $text;
                    }

                    $alert-height: rem(60px);
                    $icon-width: rem(30px);
                    $value-width: rem(100px);

                    .item {
                        @include interaction();
                        @include border-radius(3px);
                        width: 100%;
                        margin: 3px 0 12px 0;
                        height: $alert-height;
                        overflow: hidden;
                        border-left: 5px solid #ccc;
                        background-color: #fff;

                        &:hover {
                            background-color: hover(#fff);
                        }

                        .value {
                            @include font-size(xl);
                            line-height: $alert-height;
                            font-family: $title-bold;
                            text-align: center;
                            color: #333;
                            width: $value-width;
                            height: $alert-height;
                            padding: 0 6px 0 3px;
                            display: inline-block;
                            // float: left;
                        }
                        .content {
                            @include display-inline-flex();
                            @include align-items();
                            height: $alert-height;
                            width: calc(100% - #{$value-width} - #{$icon-width});
                            float: left;
                            position: relative;

                            .name {
                                @include font-size(m);
                                color: #888;
                                display: inline-block;
                                font-family: $conden;
                                text-transform: uppercase;
                                overflow: visible;
                            }
                        }

                        &.disabled {
                            @include filter(grayscale(100%));
                            @include opacity(0.5);
                        }

                        &.ok {
                            border-left-color: $main-t60;
                            .value {
                                color: $main-s20;
                            }
                        }
                        &.error {
                            border-left-color: tint($error, 60%);
                            .value {
                                color: $error;
                            }
                        }
                        &.warning {
                            border-left-color: tint($warning, 60%);
                            .value {
                                color: $warning;
                            }
                        }
                        &.info {
                            border-left-color: $main-t60;
                            .value {
                                color: $main-s20;
                            }
                        }
                    }

                    .all-done {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        width: 100%;
                        margin: 3px 0 12px 0;
                        height: 40px;
                        overflow: hidden;
                        background-color: rgba($main, 0.03);
                        text-align: center;

                        .ico {
                            @include background($image: img('tick_primary.svg'), $size: 13px, $color: rgba($main, 0.05));
                            @include border-radius(50%);
                            display: inline-block;
                            width: 25px;
                            height: 25px;
                            margin: 5px;
                            display: none;
                        }
                        .name {
                            @include font-size(sm);
                            font-family: $text;
                            color: $main;
                            line-height: 40px;
                            display: inline-block;
                            height: 40px;
                        }
                    }
                }
            }

            .activity {
                padding: 0 12px;

                .titl {
                    @include font-size(xs);
                    color: #888;
                    text-transform: uppercase;
                    font-family: $text;
                    margin: 0;
                    padding: 0;
                }

                .line {
                    padding-top: 6px;
                }
                .name,
                .value {
                    display: inline-block;
                }
                .name {
                    @include font-size(m);
                    font-family: $text;
                    margin-left: 6px;
                }
                .value {
                    @include font-size(ml);
                    font-family: $text-bold;
                    color: $main;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.portrait {
    background-color: $default-bg-color;
    height: auto;
    .content.full {
        display: block;
        width: 100%;

        .col {
            &.notes {
                max-width: 100%;
                width: 100%;
                height: auto;
                position: unset;
                display: block;
                height: calc(100vh - 90px);
                padding: 0;

                .list-notes {
                    // padding: 0 10px;
                    padding: 0;
                    height: calc(100% - 80px);
                    // overflow-y: hidden;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &.with-trial {
                        max-height: calc(100% - 70px - 12px - 190px);
                    }
                    &.editing {
                        height: calc(100% - 110px);
                    }
                }
            }
            &.alerts {
                width: 100%;
                height: auto;
            }
        }
    }
}
</style>
